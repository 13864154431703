a{
	color: inherit;
}

.ops-cdn-bld{
	font-family: 'open_sans_condensedbold';
}

.popins{
	font-family: 'poppinsregular';
}