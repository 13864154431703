body,
html{
	width:100%;
	min-height:100vh;
}

#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}

lazyimage{
	display: block;

	.fig-container{
		position: relative;
	}

	.fig-canvas{
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		overflow: hidden;
	}

	.loader{
		position: absolute;
		left:50%;
		top:50%;
		transform: translate(-50%,-50%);
	}
}

img{
	max-width: 100%;
	height: auto !important;
}