@import "_faixa-topo.scss";
@import "_mobile-controls.scss";
@import "_main-topo.scss";

.topo{
	background: #eeeeee;
	font-family: 'poppinsregular';
	font-size: 15px;

	ul{
		list-style: none;
		padding-left:0;
		margin-bottom:0;
	}

	.menu{
		font-family: 'open_sans_condensedbold';
		font-size:18px;
	}

	.buscas{
		.form-control,
		.btn{
			border-color:#FFF;
		}
	}

}
