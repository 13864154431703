.mobile-controls{
	display: flex;
	justify-content: space-between;
	background: #EEE;

	.brand{
		margin-bottom:0;
		padding:5px 15px;
		width: 270px;
		max-width: 100%;
	}

	.btn-toggle{
		border-radius: 0;
		@include button-variant(#EEE, #EEE);
		color: #333;
	}

	@include media-breakpoint-up(md){
		display: none;
	}

	&.fx{
		width: 100%;
		position:fixed;
		left:0;
		top:0;
		z-index:998;
		box-shadow:0 0 8px rgba(#000,0.4);
	}
}