
@font-face {
    font-family: 'poppinsregular';
    src: url('#{$fonts}/poppins-regular/poppins-regular-webfont.eot');
    src: url('#{$fonts}/poppins-regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppinsbold';
    src: url('#{$fonts}/poppins-bold/poppins-bold-webfont.eot');
    src: url('#{$fonts}/poppins-bold/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('#{$fonts}/opensans-regular/opensans-regular-webfont.eot');
    src: url('#{$fonts}/opensans-regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'open_sans_condensedbold';
    src: url('#{$fonts}/opensans_condensed_bold/opensanscondensed-bold-webfont.eot');
    src: url('#{$fonts}/opensans_condensed_bold/opensanscondensed-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans_condensed_bold/opensanscondensed-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans_condensed_bold/opensanscondensed-bold-webfont.woff') format('woff'),
         url('#{$fonts}/opensans_condensed_bold/opensanscondensed-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans_condensed_bold/opensanscondensed-bold-webfont.svg#open_sans_condensedbold') format('svg');
    font-weight: normal;
    font-style: normal;

}