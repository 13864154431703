// Margins
@for $i from 0 through 20{
	$m: $i * 5;

	@include margin-variant($m);
}

.noround{
	border-radius: 0;
}


.modal{
	.modal-content,
	.modal-header,
	.modal-body{
		border-radius:0;
	}

	&.modal-default{
		$theme: materialColor('blue-grey', 'darken-2');
		.modal-header{
			background: $theme;
			color: #FFF;
		}

		.btn{
			background: $theme;
			color: #FFF;

			&:hover{
				background: darken($theme, 10%);
				color: #FFF;
			}
		}
	}

	&.modal-primary{
		$theme: materialColor('blue', 'darken-2');
		.modal-header{
			background: $theme;
			color: #FFF;
		}

		.btn{
			background: $theme;
			color: #FFF;

			&:hover{
				background: darken($theme, 10%);
				color: #FFF;
			}
		}
	}

	&.modal-success{
		$theme: materialColor('green', 'darken-2');
		.modal-header{
			background: $theme;
			color: #FFF;
		}

		.btn{
			background: $theme;
			color: #FFF;

			&:hover{
				background: darken($theme, 10%);
				color: #FFF;
			}
		}
	}

	&.modal-warning{
		$theme: materialColor('orange', 'darken-2');
		.modal-header{
			background: $theme;
			color: #FFF;
		}

		.btn{
			background: $theme;
			color: #FFF;

			&:hover{
				background: darken($theme, 10%);
				color: #FFF;
			}
		}
	}

	&.modal-danger{
		$theme: materialColor('red', 'darken-2');

		.modal-header{
			background: $theme;
			color: #FFF;
		}

		.btn{
			background: $theme;
			color: #FFF;

			&:hover{
				background: darken($theme, 10%);
				color: #FFF;
			}
		}
	}
}