.rodape{
	padding:30px 0 20px 0;
	background: #eee;
	text-align: center;

	.redes-sociais{
		.icon{
			margin:0 0.5em;
		}
	}
}

p{
	font-family: 'poppinsregular';
	color: #706f6f;
}

.menu-footer{
	padding-left:0;
	list-style: none;
	margin-bottom:40px;

	font-family: 'poppinsregular';
	color: #706f6f;

	@include media-breakpoint-up(md){
		display: flex;
		justify-content: space-between;
		width: 720px;
		max-width:100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.faixa-footer{
	padding-top: 30px;
	color: #FFF;
	background: #f89c33;
	margin-bottom:20px;

	a:hover{
		color: #FFF;
	}

	.icon-area{
		height: 31px;
	}
}

.creditos{
	font-size: 13px;
	font-family: 'poppinsregular';
	color: #706f6f;

	img{
		margin-left: 0.5em;
		color: #706f6f;
	}
}
