.btn-expanded{
	padding: .375rem 1.5rem;

	&.btn-sm{
		padding: .25rem 1rem;
	}

	&.btn-lg{
		padding: .5rem 2rem;
	}
}

.btn-white{
	@include button-variant(#FFF, #FFF);
	color: #000;
}