#banner{
	width:1920px;
	max-width:100%;
	margin-left:auto;
	margin-right:auto;

	.carousel-indicators{
		@include media-breakpoint-up(md){
			font-size: 13px;
			right: 0;
			left:auto;
			top:0;
			bottom:0;
			margin-left:0;
			margin-right:0;
			flex-direction: column;
			height:100%;
			padding:15px;
		}

		@include media-breakpoint-down(sm){
			padding:0;
			margin-bottom:0;
		}

		li{
			width:1em;
			height:1em;
			margin:0.25em;
			background: #FFF;

			&.active{
				background: #f89834;
				border:1px solid rgba(#FFF,0.3);
				animation: rubberBand 0.4s linear;
			}
		}
	}
}

.selo-qldd{
	padding-bottom:30px;
	background-color: #f79945;
	color: #FFF;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;
	text-align: center;
	font-family: 'poppinsbold';

	h2{
		font-size: 16px;
	}

	.selo{
		width:125px;
		margin-left:auto;
		margin-right:auto;
		position: relative;
		z-index:5;
		margin-bottom:10px;

		@include media-breakpoint-up(md){
			margin-top: (125px / -2);
		}
	}
}

.chamada-index{
	width: 370px;
	max-width: 100%;
	position:relative;
	color: #666666;
	text-align: center;
	margin-right: auto;
	margin-left: auto;

	.title{
		position:absolute;
		top:0;
		z-index:5;
		text-align: center;
		width:calc(100% - 20px);
		left:10px;
		padding:10px 10px 55px 10px;
		overflow: hidden;
		font-family: 'poppinsbold';
		font-size: 20px;
		color: #f89834;

		span{
			position: relative;
			z-index:1;
		}

		&:before{
			content:'';
			display: block;
			position:absolute;
			background: #FFF;
			width:100%;
			padding-bottom:100%;
			left:0;
			bottom:0;
			border-radius:100%;
			z-index:0;
		}
	}

	.foto{
		margin-bottom: 20px;
		position: relative;

		&:before{
			content:'ver detalhes';
			font-family: 'poppinsbold';
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, 0.45);
			left:0;
			top: 0;
			position: absolute;
			z-index:4;
			color:#FFF;
			opacity: 0;
			transition:opacity 0.3s linear;
		}
	}

	.descript{
		font-family: 'poppinsregular';
		font-size: 14px;
		color: #706f6f;
	}

	a:hover{
		text-decoration: none;

		.foto:before{
			opacity: 1;
		}
	}

}

.noticias{
	.title{
		padding-bottom:10px;
		border-bottom: 5px solid #f89a33;
		font-family: 'poppinsbold';
		font-size: 28px;
		color:#f89a33;
		margin-bottom:40px;
	}
}

.noticia-index{
	text-align: center;
	max-width: 100%;
	width: 370px;
	margin-left: auto;
	margin-right: auto;

	.titulo{
		color: #f89a33;
		font-family: 'poppinsbold';
		font-size: 16px;
	}

	.desc{
		font-family: 'poppinsregular';
		font-size: 14px;
		color: #706f6f;
	}

	.btn{
		font-family: 'poppinsbold';
		padding:0.75rem 2.5rem;
		white-space: normal;

		border: none;

		background: rgb(248,152,52);
		background: linear-gradient(90deg, rgba(248,152,52,1) 0%, rgba(250,188,41,1) 100%);
	}
}
