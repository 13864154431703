@import "_table-produto.scss";

.page-header{
	padding: 60px 0;
	background-color: #f89834;
	color: #FFF;
	text-align: center;
	margin-bottom: 60px;
	background-position: center top;
	background-size: cover;
	background-attachment: fixed;

	h1{
		font-size: 35px;
		margin-top:0;
		margin-bottom:0;
		font-family: 'open_sans_condensedbold';
	}
}

.archive-pdf{
	width: 170px;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	text-align: center;

	.foto,
	.title,
	.desc{
		margin-bottom:10px;
	}

	.title{
		font-family: 'open_sans_condensedbold';
		color: #f89834;
	}
}

.pagination{
	.page-link{
		color: #000;
	}

	.page-link.through-way{
		background: #fff;
		color: #000;
		border-color: #dee2e6;
		cursor: default;
	}
}

.form-control-dkn{
	background: #EEEEEE;

	&::placeholder{
		color: #777;
	}
}

.titles-contato{
	color: #f89834;
	font-size: 25px;
	font-family: 'open_sans_condensedbold';
}

#mapa{
	padding-bottom: (331 / 980) * 100%;
}

.title-produtos-ctg{
	display: inline-block;
	padding:0.5rem 1rem;
	border-radius:0.3rem;
	background: #f89834;
	color: #FFF;
	@extend .ops-cdn-bld;
	font-size: 20px;
}

.produto{
	width: 275px;
	max-width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	.foto,
	.nome{
		margin-bottom: 10px;
	}

	.nome{
		@extend .ops-cdn-bld;
		color: #f89834;
		font-size: 18px;
	}
}

.categoria{
	margin-bottom:40px;

	.nome{
		color: #f89834;
		font-size: 20px;
		@extend .ops-cdn-bld;
	}

	lazyimage{
		margin:0 auto;
	}

	.btn{
		@include button-variant(#FFF, #FFF);
		color: #f89834;
		font-family: 'open_sans_condensedbold';

		&:hover{
			background: #f89834;
			color: #FFF;
		}
	}
}

.subcategoria{
	margin-bottom:40px;

	.nome{
		color: #f89834;
		font-size: 20px;
		@extend .ops-cdn-bld;
	}

	lazyimage{
		margin:0 auto;
	}
}


#slide-produto{

	.ctrls{
		position:absolute;
		font-size:40px;
		top: 50%;
		transform: translateY(-50%);
		color: #f89834;
		padding:0.35rem 0.75rem;
		text-shadow:0 0 4px #000;

		&:hover{
			color: #f89834;
		}

		&.prev{
			left:0;
		}

		&.next{
			right:0;
		}
	}
}

.cliente{
	width: 270px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;

	.foto{
		margin-bottom:10px;
	}

	.nome{
		@extend .ops-cdn-bld;
		color: #f89834;
		font-size: 18px;
	}
}

.projetos{
	text-align: justify;

	.nome{
		@extend .ops-cdn-bld;
		font-size: 20px;
		color: #f89834;
		margin-bottom: 10px;
	}

	lazyimage{
		margin: 0 auto;
	}
}

.compartilhe{
	.icon{
		margin: 0 0.5em;
	}
}

.title-desc{
	font-size: 16px;
	font-family: 'open_sans_condensedbold';
	color: #f89834;
}

.aplicacoes{
	list-style: none;
	padding-left:0;
	margin-left:-10px;
	margin-right:-10px;

	display: flex;
	flex-wrap: wrap;

	.aplicacao{
		// width: 74px;
		margin:10px;
		text-align:center;

		.legenda{
			display: block;
			// font-family: 'open_sans_condensedbold';
			font-weight: bold;
			font-size: 12px;
			margin-top: 10px;
		}
	}
}

.fachos{
	list-style: none;
	padding-left:0;
	margin-left:-10px;
	margin-right:-10px;

	display: flex;
	flex-wrap: wrap;

	.facho{
		// width: 74px;
		margin:10px;
		text-align:center;

		.legenda{
			display: block;
			// font-family: 'open_sans_condensedbold';
			font-weight: bold;
			font-size: 12px;
			margin-top: 10px;
		}
	}
}

.owl-projetos{
	padding-left:50px;
	padding-right:50px;
	position: relative;
	margin-bottom: 50px;

	.ctrls{
		@extend .text-primary;

		font-size: 40px;
		position: absolute;
		top:50%;
		transform: translateY(-50%);

		&.prev{
			left: 0;
		}

		&.next{
			right: 0;
		}
	}
}
