.faixa-topo{
	padding: 10px 0;

	.container{
		display: flex;
	}

	ul{
		display: flex;
	}
}

.area-restrita{
	color: #f89834;
	font-family: Arial, sans-serif;
	font-weight:bold;
	font-size: 14px;

	a{
		color: #f89834;
	}

	li + li:before{
		content:'|';
		display: inline-block;
		margin:0 0.5em;
	}

}

.buscas{
	.form-control,
	.btn{
		border-radius:0 0 0 0;
	}

	.btn{
		border-left:none;
		border-color: rgba(#000,0.2);
		color: #f89834 !important;
	}

	.form-control,
	.form-control::placeholder{
		color: #f89834;
	}
}

.idiomas{
	li + li {
		margin-left:0.5em;
	}
}

@include media-breakpoint-up(lg){
	.faixa-topo{
		.container{
			display: flex;
			justify-content: flex-end;
			align-items: center;

			& > * ~ *{
				margin-left:30px;
			}
		}
	}
}

@include media-breakpoint-only(md){
	.faixa-topo{
		.container{
			display: flex;
			justify-content: center;
			align-items: center;

			& > * ~ *{
				margin-left:30px;
			}
		}
	}
}

@include media-breakpoint-down(sm){
	.buscas{
		position: fixed;
		left:0;
		top:-200px;
		left:0;
		width:100%;
		padding:0 30px;
		transition:top 0.6s linear;
		z-index: 999;

		&.active {
			top: 80px;
		}
	}

	.bg-buscas{
		position: fixed;
		left:0;
		top:0;
		z-index: 998;
		width: 100%;
		height:100%;
		background: rgba(#000,0.8);
	}

	.faixa-topo{
		.container{
			justify-content: space-between;
		}
	}
}
