.icon{
	background-image: url('#{$imgs}/sprite.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 221px 172px;
	
	&.br{
		width: 23px;
		height: 23px;
		background-position: -168px -107px;
	}
	&.envelope{
		width: 32px;
		height: 23px;
		background-position: -168px 0px;
	}
	&.facebook-square{
		width: 46px;
		height: 45px;
		background-position: -56px 0px;
	}
	&.facebook{
		width: 31px;
		height: 30px;
		background-position: -112px -55px;
	}
	&.fone{
		width: 11px;
		height: 31px;
		background-position: -210px 0px;
	}
	&.google-plus-square{
		width: 46px;
		height: 45px;
		background-position: -56px -55px;
	}
	&.instagram{
		width: 31px;
		height: 30px;
		background-position: 0px -110px;
	}
	&.linkedim{
		width: 31px;
		height: 30px;
		background-position: -41px -110px;
	}
	&.linkedin-square{
		width: 46px;
		height: 45px;
		background-position: 0px 0px;
	}
	&.map-marker{
		width: 23px;
		height: 31px;
		background-position: -168px -33px;
	}
	&.twitter-square{
		width: 46px;
		height: 45px;
		background-position: -112px 0px;
	}
	&.twitter{
		width: 31px;
		height: 30px;
		background-position: -82px -110px;
	}
	&.usa{
		width: 23px;
		height: 23px;
		background-position: -168px -74px;
	}
	&.whatsapp-square{
		width: 46px;
		height: 45px;
		background-position: 0px -55px;
	}
	&.whatsapp{
		width: 22px;
		height: 22px;
		background-position: 0px -150px;
	}
	&.youtube{
		width: 31px;
		height: 30px;
		background-position: -123px -110px;
	}
}


