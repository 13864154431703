.main-topo{}

@include media-breakpoint-up(md){

	.topo{

		.menu{

			& > li:hover > a,
			& > li.active > a{
				color:inherit;
			}

		}

	}

}

@include media-breakpoint-up(lg){
	.main-topo{
		margin-top:15px;
		padding-left:15px;
		padding-right:15px;
		margin-left:auto;
		margin-right:auto;
		display: flex;

		.menu{
			margin-left:30px;
			flex-grow:1;
			display: flex;
			justify-content: space-between;
			align-self:flex-end;

			a{
				display: block;
				padding-bottom: 38px;
				position:relative;
				line-height: 2;

				&:after{
					content:'';
					width:100%;
					height:0;
					transition: height 0.3s linear;
					background: #f89834;
					display: block;
					position:absolute;
					left:0;
					bottom:0;
				}
			}

			.active a:after,
			a:hover:after{
				height: 38px;
			}

			a:hover{
				text-decoration: none;
			}
		}
	}
}

@include media-breakpoint-only(xl){
	.main-topo{
		width: map-get($container-max-widths, xl);

		.menu{
			padding-left:100px;
		}
	}
}

@include media-breakpoint-only(lg){
	.main-topo{
		width: map-get($container-max-widths, lg);
	}
}

@include media-breakpoint-only(md){
	.main-topo{
		width: map-get($container-max-widths, md);
		margin-top:15px;
		padding-left:15px;
		padding-right:15px;
		margin-left:auto;
		margin-right:auto;
		// display: flex;

		.brand{
			text-align: center;
			margin-bottom:20px;
		}

		.menu{
			display: flex;
			justify-content: space-between;

			a{
				display: block;
				border-bottom:15px solid transparent;
				line-height:2;

				&:hover{
					text-decoration: none;
				}
			}

			.active a,
			a:hover{
				border-color: #f89834;
			}
		}
	}
}


@include media-breakpoint-down(sm){
	.main-topo{
		position: fixed;
		left:-280px;
		top:0;
		width: 250px;
		height:100%;
		overflow: auto;
		z-index:999;
		background: #eee;
		box-shadow:0 0 10px rgba(#000,0.8);
		transition: left 0.6s linear;

		&.active{
			left:0;
		}

		.brand{
			padding:30px 15px 15px 15px;
		}

		.menu{
			a{
				display: block;
				padding: 10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

			.active a,
			a:hover{
				color:#FFF;
				background: #f89834;
				text-decoration: none;
			}

			.active a{
				border-color: rgba(#000,0.2);
			}
		}
	}

	.bg-menu{
		position:fixed;
		left:0;
		top:0;
		z-index:998;
		background: rgba(#000,0.8);
		width: 100%;
		height:100%;
	}
}
