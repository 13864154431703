.table-produto{
	width: 100%;
	text-align: center;

	&,
	.detalhes-produto{
		font-size: 12px;
	}

	& > thead {
		& > tr{
			& > th{
				padding:10px 8px;
				background: #DDDDDD;
				color: #f89834;
				vertical-align: top;
				white-space:nowrap;
			}
		}
	}

	& > tbody{


		.line-p{
			td,
			th{
				background: #EEEEEE;
				padding:8px;
				border-top:2px solid #FFF;
			}

			.btn{
				@include button-variant(#eee, #eee);
				color: #f89834;
				font-family: 'open_sans_condensedbold';

				&:hover{
					color: #f89834;
				}
			}
		}

	}

	.detalhes-produto{
		width: 100%;

		& > thead,
		& > tbody{
			& > tr{
				& > td,
				& > th{
					padding:8px;
					vertical-align: top;
				}
			}
		}

		& > thead{
			& > tr{
				& > td,
				th{
					color: #f89834;
					font-size: 14px;
					font-family: 'open_sans_condensedbold';
				}
			}
		}

		.btn{
			display: flex;
			align-items: center;
			text-align: left;
			white-space: normal;

			span{
				display: block;
				flex-grow: 1;
			}
		}
	}
}
