@import "tipografia/_font-face.scss";
@import "tipografia/_tipografia.scss";

@import "header/_header.scss";

@import "footer/_footer.scss";

@import "content/_index.scss";
@import "content/_internas.scss";

.btn-primary{
	background-color: #f89834!important;
	border-color: #f89834!important;
	color: #FFF!important;

	&:hover{
		background-color: #f89834!important;
		border-color: #f89834!important;
		color: #FFF!important;
	}
}

b{
	color: #f89834!important;
}

.menu-footer{

	a{
		&:hover{
			color: #f89834!important;
		}
	}
}

.projetos{

	a{
		text-decoration: none;
	}
}

.page-item.active .page-link{
	background-color: #f89834;
	border-color: #f89834;

	&:hover{
		background-color: #f89834;
		border-color: #f89834;
	}
}

.text-primary{
	color: #f89834!important;
}
